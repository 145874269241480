<template>
  <SimpleSite title="Impressum">
    <div style="color: black">
      <h1>Impressum</h1>

      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>Mein Gutachter24<br/>
        Böttgerstraße 7<br/>
        13357 Berlin</p>

      <p>Geschäftsführer: Ümit Dogangüzel</p>

      <h2>Kontakt</h2>
      <p>Telefon: 01625112511<br/>
        E-Mail: info@mein-gutachter24.com</p>

      <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
      <p>Berufsbezeichnung:<br/>
        KFZ-Gutachten</p>
      <p>Verliehen in:<br/>
        Deutschland</p>
      <p>Es gelten folgende berufsrechtliche Regelungen:</p>
      <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
      <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
        teilzunehmen.</p>

      <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
    </div>
  </SimpleSite>
</template>

<script>
import SimpleSite from "../components/SimpleSite";

export default {
  components: {SimpleSite}
}
</script>
